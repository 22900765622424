<template>
  <main class="ma-4 elevation-2" style="background-color: white">
    <v-tabs slider-color="main" riple show-arrows>
      <v-tab class="cursor-pointer">
        <div class="main--text">{{ translations.manufacturerAndCarrier }}</div>
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="manufacturerCarriers"
      :items-per-page="pageSize"
      :update:options="{
        page: page,
        itemsPerPage: pageSize
      }"
      dense
    >
      <template v-slot:footer>
        <div class="d-flex justify-end align-center pa-2">
          <span class="mr-2">{{ page === 1 ? initialNumber : (page - 1) * 10 + 1 }} - {{ (page - 1) * 10 + manufacturerCarriers.length }} di {{ totalManufacturerCarriers }}</span>

          <v-btn @click="onPreviousTableChange" class="mr-2" icon :disabled="page === 1">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn @click="onNextTableChange" class="" icon :disabled="page === totalPages"> <v-icon>mdi-chevron-right</v-icon></v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn color="main" class="white--text" height="32px" depressed @click="openSaveNewManufacturerCarrierDialog">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            {{ translations.newManufacturerCarrier }}
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.operations="{ item }">
        <div class="d-flex justify-space-between">
          <div class="d-flex">
            <v-icon small class="mr-2" @click="openEditManufacturerCarrierDialog(item)">
              mdi-pencil
            </v-icon>
            <v-icon small color="error lighten-1" class="mr-2" @click="deleteManufacturerCarrier(item)">
              mdi-delete
            </v-icon>
          </div>
        </div>
      </template>
    </v-data-table>

    <!-- new Manufacturer/Carrier dialog -->
    <v-dialog v-model="dialog" max-width="430px" @keydown.enter="disabled ? null : saveManufacturerCarrier(manufacturerCarrier)">
      <v-card class="pa-2">
        <v-card-title>
          <v-row class="ma-2" justify="center">
            <h3 class="main--text">
              {{ dialogTitle }}
            </h3>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-col class="pa-0">
            <b>{{ translations.name }}</b>
            <v-text-field ref="name" v-model="manufacturerCarrier.name" outlined hide-details dense></v-text-field>
          </v-col>
          <v-col class="pa-0 mt-4">
            <b>{{ translations.description }}</b>
            <v-text-field v-model="manufacturerCarrier.description" outlined hide-details dense></v-text-field>
          </v-col>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="main" class="white--text" depressed :disabled="disabled" @click="saveManufacturerCarrier(manufacturerCarrier)">
            {{ translations.confirm }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="ma-0" align="center" justify="center">
      <v-col class="pa-2" cols="auto">
        <v-alert v-model="showErrorAlert" dense text type="error" dismissible>{{ translations.cannotDeleteManufacturer }}</v-alert>
      </v-col>
    </v-row>
  </main>
</template>

<script>
import { translations } from "@/utils/common";
import manufacturerCarrierQuery from "@/graphql/ManufacturersAndCarriers.gql";
import saveManufacturerCarrier from "@/graphql/SaveManufacturerCarrier.gql";
import deleteManufacturerCarrier from "@/graphql/DeleteManufacturerCarrier.gql";
import { mdiPlus } from "@mdi/js";

export default {
  apollo: {
    manufacturers: {
      query: manufacturerCarrierQuery,
      variables() {
        return {
          page: this.page,
          pageSize: this.pageSize
        };
      },
      fetchPolicy: "network-only",
      result({ data }) {
        const { content, hasNext, totalPages, totalElements } = data.manufacturers;
        this.totalManufacturerCarriers = totalElements;
        this.totalPages = totalPages;
        this.manufacturerCarriers = content;
        this.hasNext = hasNext;
      }
    }
  },

  data: () => ({
    headers: [
      { text: translations.name, value: "name" },
      { text: translations.description, value: "description" },
      { text: translations.operations, value: "operations" }
    ],
    manufacturerCarrier: {
      name: "",
      description: ""
    },
    manufacturerCarriers: [],
    hasNext: true,
    page: 1,
    pageSize: 10,
    totalPages: null,
    totalManufacturerCarriers: null,
    dialog: false,
    dialogTitle: "",
    showErrorAlert: false
  }),

  methods: {
    async saveManufacturerCarrier(manufacturerCarrier) {
      await this.$apollo
        .mutate({
          mutation: saveManufacturerCarrier,
          variables: { manufacturerDTO: manufacturerCarrier }
        })
        .then(res => {
          this.dialog = false;
          const newManufacturerCarrier = res.data.saveManufacturer;
          const index = this.manufacturerCarriers.findIndex(car => car.id == newManufacturerCarrier.id);
          if (index == -1) this.manufacturerCarriers.push(newManufacturerCarrier);
          else {
            this.manufacturerCarriers[index] = newManufacturerCarrier;
            this.manufacturerCarriers.splice(index, 1, newManufacturerCarrier);
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
      this.incrementPages();
    },

    incrementPages() {
      this.totalManufacturerCarriers = this.manufacturerCarriers.length;
      this.hasNext = true;
      this.totalPages = Math.ceil(this.manufacturerCarriers.length / this.pageSize);
    },

    openEditManufacturerCarrierDialog(manufacturerCarrier) {
      this.dialog = true;

      this.manufacturerCarrier = {
        id: manufacturerCarrier.id,
        name: manufacturerCarrier.name,
        description: manufacturerCarrier.description
      };

      this.dialogTitle = this.translations.edit + " " + manufacturerCarrier.name;
    },

    openSaveNewManufacturerCarrierDialog() {
      this.dialog = true;
      this.manufacturerCarrier = {
        name: "",
        description: ""
      };
      this.dialogTitle = this.translations.newManufacturerCarrier;

      // focus on first input field
      setTimeout(() => {
        this.$refs.name.focus();
      }, 200);
    },

    async deleteManufacturerCarrier(manufacturerCarrier) {
      await this.$apollo
        .mutate({
          mutation: deleteManufacturerCarrier,
          variables: { manufacturerId: manufacturerCarrier.id }
        })
        .then(() => {
          const index = this.manufacturerCarriers.indexOf(manufacturerCarrier);
          this.manufacturerCarriers.splice(index, 1);
          this.totalManufacturerCarriers -= 1;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.showErrorAlert = true;
          setTimeout(() => (this.showErrorAlert = false), 6000);
        });
    },

    onNextTableChange() {
      if (!this.hasNext) return;
      this.page++;
    },

    onPreviousTableChange() {
      if (this.page === 1) return;
      this.page--;
    }
  },

  computed: {
    translations: () => translations,

    icons: () => ({
      mdiPlus
    }),

    initialNumber() {
      if (this.manufacturerCarriers.length === 0) return 0;

      return 1;
    },

    disabled() {
      return !this.manufacturerCarrier.name || !this.manufacturerCarrier.description;
    }
  }
};
</script>
